import { RootState } from 'Store'
import { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import { StreamState } from 'reducers/stream'
import terms from 'terms.json'
import './FluxState.scss'
import getCSSValue from './utils'

const getBackgroundColor = (state: boolean) => (state ? getCSSValue('--color-green-500')
  : getCSSValue('--color-red-500'))

export default function FluxState(): ReactElement {
  const { streamState } = useSelector((state: RootState) => state.stream as StreamState)
  return (
    <div id="flux" className="d-flex justify-content-center align-items-center">
      <span className="label">{terms.Common.observations}</span>
      <div className="indicator ml-2" style={{ backgroundColor: getBackgroundColor(streamState.state) }} />
    </div>
  )
}
