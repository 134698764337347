import { ReactElement } from 'react'
import { CirculationType } from 'types'
import './CirculationNumber.scss'
import getCSSValue from 'components/utils'

const getBorderColor = (type: CirculationType) => {
  switch (type) {
    case CirculationType.B:
      return getCSSValue('--color-blue-600')

    case CirculationType.D:
      return getCSSValue('--color-green-500')

    default:
      return getCSSValue('--color-grey-800')
  }
}

type Props = {
  type: CirculationType;
  number: string;
}

export default function CirculationNumber({ type, number }: Props): ReactElement {
  return (
    <div
      className="circulation-number"
      style={{ borderColor: getBorderColor(type) }}
    >
      {number}
    </div>
  )
}
