/* eslint-disable @typescript-eslint/no-explicit-any */
import { get } from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import URI from 'services/uri'

const getTime = createAsyncThunk(
  'themis/getImports',
  async (clientTime: any, thunkApi) => {
    try {
      const response = await get(`/${URI.themis}/sync`, { ct: clientTime })
      return [response, clientTime]
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const ThemisServices = {
  getTime,
}

export default ThemisServices
