/* eslint-disable camelcase */
export enum CirculationState {
  approaching = 'announced_on_platform',
  onPlatform = 'on_platform',
  leaving = 'leaving',
}

export enum TrackNumber {
  track42 = '42',
  track44 = '44',
}

export enum CirculationType {
  B = 'B',
  D = 'D',
  other = 'other',
  none = 'none',
}

export type PriorityCirculation = {
  number: string;
  track: TrackNumber;
  train_type: CirculationType;
  priority_reason: string;

}
export type CirculationProps = {
  number: string;
  train_type: CirculationType;
  state: CirculationState | '';
}

export enum EventType {
  priority = 'priority',
  circulations = 'circulations',
  stream_state = 'stream_state'
}

export type PriorityEvent = {
  type: EventType.priority;
  circulations: PriorityCirculation[];
}

export type CirculationEvent = {
  type: EventType.circulations;
  track: TrackNumber | null;
  circulations: CirculationProps[];
}

export type FluxStateEvent = {
  type: EventType.stream_state;
  state: boolean;
}

export type ServerSync = {
  delta: number;
  server_time: number;
}
