import { ReactElement } from 'react'
import { CirculationState, CirculationType } from 'types'
import terms from 'terms.json'
import './Badge.scss'
import getCSSValue from 'components/utils'

type Params = {
  label: string;
  backgroundColor: string;
  color: string;
}

const getTypeColor = (type: CirculationType) => {
  switch (type) {
    case CirculationType.B:
      return getCSSValue('--color-blue-600')

    case CirculationType.D:
      return getCSSValue('--color-green-500')

    default:
      return getCSSValue('--color-grey-800')
  }
}
const getParams = (s: CirculationState, type: CirculationType): Params => {
  switch (s) {
    case CirculationState.approaching:
      return {
        label: terms.Track.approaching,
        color: getTypeColor(type),
        backgroundColor: 'white',
      }
    case CirculationState.onPlatform:
      return {
        label: terms.Track.onPlatform,
        color: getTypeColor(type),
        backgroundColor: 'white',
      }
    default:
      return {
        label: '',
        color: '',
        backgroundColor: '',
      }
  }
}

type Props = {
  state: CirculationState;
  type: CirculationType;
}

export default function Badge({ state, type }: Props): ReactElement {
  const { label, color, backgroundColor } = getParams(state, type)
  if (state !== CirculationState.leaving) {
    return (
      <div>
        <div style={{ color, backgroundColor }} className="custom-badge ml-3">
          {label}
        </div>
      </div>
    )
  }
  return null
}
