/* eslint-disable camelcase */
import Priority from 'components/Priority/Priority'
import React, { ReactElement } from 'react'
import {
  CirculationProps,
  CirculationState,
} from 'types'

import Badge from './Badge'
import './Track.scss'
import {
  getBackgroundColor, getDefaultCirculation, getTrainIcon, getWidth,
  shouldDisplayByState, shouldDisplayByType, shouldDisplayCirculationBar,
} from './utils'
import CirculationNumber from './CirculationNumber'

type Props = {
  trackProps: CirculationProps[];
  trackName: string;
  hidePriority: boolean
}

export default function Track({
  trackProps, trackName, hidePriority,
}: Props): ReactElement {
  const hasRightCirculation = trackProps.length > 0
    && trackProps.filter(p => shouldDisplayByType(p.train_type))?.length > 0
  const hasRightState = trackProps.length > 0 && trackProps.filter(p => shouldDisplayByState(p.state))?.length > 0
  const defaultCirculation = getDefaultCirculation(trackProps)

  return (
    <div
      className={`track d-flex flex-column justify-content-center align-items-start ${
        !shouldDisplayByState ? 'faded' : ''
      } position-relative`}
    >
      <div className="w-100 d-flex pt-3 position-absolute" style={{ top: 0 }}>
        {hasRightCirculation && hasRightState && (
          <div
            className={`circulation-type text-center type-${defaultCirculation.train_type} ml-3`}
          >
            {defaultCirculation.train_type === 'D' || defaultCirculation.train_type === 'B'
              ? defaultCirculation.train_type : ''}
          </div>
        )}
        <div
          className="custom-badge track-name ml-3"
          style={{
            backgroundColor: getBackgroundColor(defaultCirculation),
          }}
        >
          {trackName}
        </div>
        {defaultCirculation.state !== '' && defaultCirculation.train_type !== 'none' && (
          <Badge state={defaultCirculation.state} type={defaultCirculation.train_type} />
        )}
      </div>
      <div className="w-100 train-container">
        {trackProps.map(({ state, train_type: type }) => (
          <>
            {state === CirculationState.approaching && (
              <div className="train-icon d-flex justify-content-center">
                <div>
                  <img src={getTrainIcon(type)} height={30} alt="train icon" />
                </div>
              </div>
            )}
          </>
        ))}
        <div className="track-background">
          <div className="platform-indicator" />
          {trackProps.map(({ state, train_type: type, number }, index) => (
            <React.Fragment key={number}>
              {shouldDisplayCirculationBar(state, trackProps[index], trackProps) && (
                <div className={`circulation ${getWidth(state)} type-${type}`} />
              )}
              {shouldDisplayByState(state) && (
                <img
                  className={`platform-train ${state === CirculationState.onPlatform ? 'arrived' : ''}`}
                  src={getTrainIcon(type)}
                  height={45}
                  alt="train icon"
                />
              )}
            </React.Fragment>
          ))}
          {trackProps.map(({ state, number, train_type: type }) => (
            <>
              {state === CirculationState.onPlatform && (
              <CirculationNumber type={type} number={number} />
              )}
            </>
          ))}

        </div>
      </div>
      <div className="priority-container">
        <Priority
          hidden={hidePriority}
        />
      </div>
    </div>
  )
}
