import clockIcon from 'assets/icons/clock.svg'
import {
  ReactElement, useEffect, useRef, useState,
} from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'
import './Clock.scss'

export const Number = ({ value = 0 }: { value: number }): ReactElement => {
  const result = String(value).padStart(2, '0')
  return (
    <div className="digital">
      <div>{result}</div>
    </div>
  )
}

export const Word = ({ value }: { value: string }): ReactElement => (
  <div className="digital">
    <div>{value}</div>
  </div>
)

export const Clock = (): ReactElement => {
  const { clockSkew } = useSelector((state: RootState) => state.stream)
  const fetchStatus = useRef<NodeJS.Timer | NodeJS.Timeout>()
  const [syncedClientTime, setSync] = useState<Date | undefined>(undefined)

  useEffect(() => {
    if (clockSkew) {
      fetchStatus.current = setInterval(() => {
        setSync(new Date(new Date().getTime() + clockSkew))
      }, 1000)
    }
    return () => clearInterval(fetchStatus.current)
  }, [clockSkew])

  return (
    <div className="h-100 d-flex justify-content-center align-items-center mr-3" id="clock">
      <div className="d-flex justify-content-center clock-wrapper">
        {clockSkew && (
        <>
          <img className="clock-icon" src={clockIcon} alt="clock icon" height={18} />
          <Number value={syncedClientTime?.getHours()} />
          <Word value=":" />
          <Number value={syncedClientTime?.getMinutes()} />
          <Word value=":" />
          <Number value={syncedClientTime?.getSeconds()} />
        </>
        )}

      </div>
    </div>
  )
}
