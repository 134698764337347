/* eslint-disable camelcase */
import { ReactElement, useEffect } from 'react'

import Track from 'components/Track/Track'
import { TrackNumber } from 'types'

import terms from 'terms.json'
import FIFOStream from 'components/Stream/Stream'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import { StreamState } from 'reducers/stream'
import ThemisServices from 'services/ThemisServices'
import Loader from 'components/Loader'

export default function Home(): ReactElement {
  const dispatch = useDispatch()
  const {
    track42, track44, priority, clockSkew,
  } = useSelector((state: RootState) => state.stream) as StreamState

  useEffect(() => {
    const ct = (new Date()).getTime()
    dispatch(ThemisServices.getTime(ct))
  }, [])

  return (
    <div className="d-flex w-100 h-100">
      {!clockSkew ? <Loader /> : (
        <>
          <FIFOStream />
          <div style={{ flex: 2 }} className="d-flex flex-column">
            <Track
              trackProps={track42}
              trackName={terms.Track.names[42]}
              hidePriority={priority.circulations[0]?.track !== TrackNumber.track42}
            />
            <div style={{ height: '14px', background: 'var(--color-carbone)' }} />
            <Track
              trackProps={track44}
              trackName={terms.Track.names[44]}
              hidePriority={priority.circulations[0]?.track !== TrackNumber.track44}
            />
          </div>
        </>
      )}
    </div>
  )
}
