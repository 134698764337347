import {
  useEffect, Suspense, ReactElement, useState,
} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Router, Switch, Route, Redirect,
} from 'react-router-dom'
import { auth } from '@osrdata/app_core'

import Loader from 'components/Loader'
import TopBar from 'components/TopBar/TopBar'
import { Clock } from 'components/Clock/Clock'
import { RootState } from 'Store'
import Home from 'Home'
import history from 'customHistory'
import './App.scss'
import { DateComponent } from 'components/Date/Date'
import FluxState from 'components/FluxState'
import AccessDenied from 'components/AccessDenied/AccessDenied'
import terms from 'terms.json'

const APP_NAME = 'FIFO+'
const ACCESS_PERM = 'eris::PPN-ACCESS'

const ROUTES = [
  {
    path: '/',
    component: Home,
  },
]

export default function App(): ReactElement {
  const dispatch = useDispatch()
  const { isLogged, appPermissions } = useSelector((state: RootState) => state.user)
  const [isLoading, setIsLoading] = useState(true)
  const [accessDenied, setAccessDenied] = useState(false)

  useEffect(() => {
    dispatch(auth.attemptLoginOnLaunch())
  }, [])

  useEffect(() => {
    if (isLogged && appPermissions.length > 0) {
      if (!appPermissions.includes(ACCESS_PERM)) {
        setAccessDenied(true)
      }
      setIsLoading(false)
    }
  }, [isLogged, appPermissions])

  const innerComponent = (
    <div className="d-flex justify-content-end h-100" style={{ flex: 1 }}>
      <FluxState />
      <DateComponent />
      <Clock />
    </div>
  )

  return (
    <>
      <Suspense fallback={<Loader />}>
        <Router history={history}>
          <TopBar appName={APP_NAME} innerComponent={innerComponent} hasAccess={!isLoading && !accessDenied} />
          {isLoading && <div id="app"><Loader message={terms.Common.loading} /></div>}
          {!isLoading && (accessDenied ? <AccessDenied /> : (
            <div id="app">
              <Switch>
                {ROUTES.map(route => (
                  <Route key={route.path} component={route.component} path={route.path} />
                ))}
                <Redirect from="" to="/" />
              </Switch>
            </div>
          )
          )}
        </Router>
      </Suspense>
    </>
  )
}
