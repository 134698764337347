/* eslint-disable max-len */
import 'App.scss'
import { RootState } from 'Store'
import { isDevEnv } from 'components/utils'
import { GATEWAY_DOMAIN } from 'config/config'
import {
  MutableRefObject, ReactElement, useEffect, useRef,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { StreamState, updateFluxState } from 'reducers/stream'
import {
  DOWN_FLUX_EVENT, Event,
  checkIsDown, debouncedRestart,
  handleEvent,
} from './utils'

export default function FIFOStream(): ReactElement {
  const dispatch = useDispatch()
  const { shouldRestart } = useSelector((state: RootState) => state.stream) as StreamState

  const ws: MutableRefObject<WebSocket> = useRef()
  const wsUrl = isDevEnv() ? 'prio_paris_nord_mock' : 'prio_paris_nord'

  useEffect(() => {
    ws.current = new WebSocket(
      `wss://${GATEWAY_DOMAIN.url}/eris/${wsUrl}/?_auth_token=${`${localStorage.getItem(
        'access_token',
      )}`}`,
    )
    ws.current.onmessage = (event: MessageEvent) => {
      checkIsDown()
      handleEvent(JSON.parse(event.data) as Event)
    }
    ws.current.onclose = () => {
      dispatch(updateFluxState(DOWN_FLUX_EVENT))
      debouncedRestart(!shouldRestart)
    }
    const wsCurrent = ws.current
    return () => {
      wsCurrent.close()
    }
  }, [shouldRestart])

  return (
    <> </>
  )
}
